.header-meta-bar {
	padding: 15px 0;
	height: 58px;
	width: 100%;
	background-color: $white-base;
	z-index: 10;

	.logo {
		display: inline-block;
	}
}

.navbar-fixed-top {
	top: 60px;
}
