// These styles are meant to work in a Bootstrap environment
// Note: The styles act just as a minor demo and are far from perfect. You can base your styles upon this file.

.tx-sgnews-tab-title {
	padding: 10px 0;
	font-size: 22px;
	display: none;

	.noJs & {
		display: block;
	}
}

.tx-sgnews .tab-pane {
	padding-top: 40px;
}

.tx-sgnews .tabs-menu-button {
	display: none;

	.noJs & {
		display: block;
	}
}

.tx-sgnews-list {
	list-style: none;
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;

	li {
		margin-bottom: 30px;
		display: flex;
		flex-direction: column;
	}

	a {
		text-decoration: none;
		display: flex;
		flex-direction: column;
		flex-grow: 1;
	}
}

.tx-sgnews-teaser-description {
	color: $gray-dark;
}

.tx-sgnews-categories,
.tx-sgnews-overview {
	.tx-sgnews-teaser-image {
		overflow: hidden;
		background: #FFF;
		border-bottom: 1px solid #D9D9D9;
		text-align: center;
		min-height: 215px;

		img {
			max-height: 200px;
			width: auto;
			border: 1px solid $nav-tabs-border-color;
		}
	}

	.tx-sgnews-teaser-title h2 {
		margin: 0 0 10px;
		font-size: 22px;
	}

	.tx-sgnews-teaser-image-stretched {
		padding: 0;
		background-size: cover;
		background-position: center;
	}

	.tx-sgnews-teaser-inner,
	.tx-sgnews-teaser-meta {
		padding: 18px 18px 6px;
		border: 1px solid $nav-tabs-border-color;
		border-top: 0;
	}

	.tx-sgnews-teaser-inner {

		h2,
		.tx-sgnews-readmore {
			color: #000;
		}
	}

	.tx-sgnews-teaser-meta {
		padding-top: 10px;
		padding-bottom: 10px;
		background: $gray-dark;
		color: #FFF;
		border-color: $gray-dark;
		font-size: 14px;

		span {
			margin: 0 4px 0 0;
			display: inline-block;

			&:before {
				margin: 0 4px 0 0;
				content: '|';
			}

			&:first-child:before {
				display: none;
			}
		}
	}

	.tx-sgnews-list .tx-sgnews-teaser-inner {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
	}

	.tx-sgnews-list .tx-sgnews-teaser-description {
		flex-grow: 1;
	}

	.tx-sgnews-category-title {
		margin-bottom: 30px;
		border-bottom: 1px solid $nav-tabs-border-color;
	}
}

.tx-sgnews-single-header {
	padding: 10px 0 20px;
	position: relative;

	a {
		color: #FFF;
	}
}

.tx-sgnews-single-image {
	img {
		display: none;
	}
}

@media (min-width: $screen-md-min) {

	.tx-sgnews-single-header-image {
		padding: 0 !important;

		.tx-sgnews-single-header-content {
			position: absolute;
			z-index: 1;
			left: 0;
			right: 0;
			bottom: 0;
		}

		.tx-sgnews-single-image {
			width: 100%;
			height: 100%;
			position: relative;
			z-index: 0;

			&:before {
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				content: '';
				background: rgba(0, 0, 0, .5);
				z-index: 0;
			}

			img {
				width: 100%;
				height: auto;
				display: block;
			}
		}
	}
}

.tx-sgnews-footer-browser-previous {
	margin-bottom: 20px;
	padding: 10px 14px;
	display: block;

	@media (min-width: $screen-sm-min) {
		margin: 0;
		float: left;
		display: inline-block;
	}

	&:before {
		margin-right: 5px;
		font-family: 'Glyphicons Halflings';
		content: '\e257';
		font-size: 10px;
		width: 20px;
		height: 20px;
		display: inline-block;
		border-radius: 50%;
		text-align: center;
		line-height: 2;
		background-color: $btn-info-color;
		color: $btn-info-bg;
	}
}

.tx-sgnews-footer-browser-next {
	padding: 10px 14px;
	display: block;

	@media (min-width: $screen-sm-min) {
		margin: 0;
		float: right;
		display: inline-block;
	}
	&:after {
		margin-left: 5px;
		font-family: 'Glyphicons Halflings';
		content: '\e258';
		font-size: 10px;
		width: 20px;
		height: 20px;
		display: inline-block;
		border-radius: 50%;
		text-align: center;
		line-height: 2;
		background-color: $btn-info-color;
		color: $btn-info-bg;
	}
}

.tx-sgnews-single-footer {
	@include clearfix;
}

.tx-sgnews-single-content {
	margin-bottom: 25px;
}

.tx-sgnews-categories-tabmenu-outer a {
	text-decoration: none;
}

.hide_initally {
	display: none;
}

.tx-sgnews-comments-item {
	margin-bottom: 40px;

	&.first-comment {
		margin-top: 40px;
	}

	.comment-image {
		float: left;
		width: 80px;

		img {
			width: 80px;
		}
	}

	.comment-text {
		padding: 0 0 0 20px;
		float: left;
		width: calc(100% - 100px);
	}

	@media (min-width: $screen-md-min) {
		.comment-image {
			width: 150px;

			img {
				width: 150px;
			}
		}

		.comment-text {
			width: calc(100% - 150px);
		}
	}

	h3 {
		margin-top: 0;
	}

	.date {
		padding: 0 0 10px;
		display: block;
		color: lighten(#000, 40%);
	}

	.clear {
		clear: both;
	}
}

.tx-sgnews-readmore {
	text-decoration: underline;
}

.typo3-messages {
	list-style: none;
}
