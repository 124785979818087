@import 'Preloader/preloader';

.default-lightbox {
	cursor: zoom-in;
}

.default-shadowbox .mfp-container,
.default-shadowbox.mfp-bg {
	opacity: 0;
	transition: all .3s ease-out;
}

.default-shadowbox.mfp-ready .mfp-container {
	opacity: 1;
}

.default-shadowbox.mfp-ready.mfp-bg {
	opacity: .8;
}

.default-shadowbox.mfp-removing .mfp-container,
.default-shadowbox.mfp-removing.mfp-bg {
	opacity: 0;
}

.default-preloader {
	@include preloader;

	&::after {
		@include inline-svg($loading-spinner);
		margin: -15px 0 0 -15px;
		width: 30px;
		height: 30px;
		z-index: 100;
	}
}

.default-preloader-fixed {
	@include preloader-fixed;
}

.default-preloader-display-helper {
	@include preloader-display-helper;
}

.mfp-figure figcaption {
	&::before {
		display: none;
	}
}

.mfp-title {
	padding-top: 5px;
}

.mfp-content {
	max-width: 1250px;

	.mfp-inline-holder & {
		max-height: 90%;
		overflow-y: auto;
	}
}
