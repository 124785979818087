$x-large: 1100px !default;
$large: 992px !default;
$middle: 720px !default;
$small: 480px !default;

@mixin pie-clearfix {
	&::after {
		content: '';
		display: table;
		clear: both;
	}
}

@mixin breakpoint($point) {
	@if $point != retina {
		@media only screen and (max-width: $point) {
			@content;
		}
	} @else if $point == retina {
		@media (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi), (-o-min-device-pixel-ratio: 5/4) {
			@content;
		}
	}
}

@mixin breakpoint-min($point) {
	@media only screen and (min-width: $point) {
		@content;
	}
}
