// #####################################################################################
// ## This file overwrites the default navbar dropdown behaviour.                     ##
// ## If you include this file, the dropdowns will be invoked on hover, not on click. ##
// #####################################################################################

// scss-lint:disable SelectorDepth

.dropdown {

	> .dropdown-menu {
		display: none;
	}

	&:hover > .dropdown-menu {
		display: block;
	}
}

// overwrite the styles that would be invoked by the .open class
// the following changes render the .open class pretty much useless

// default (light) navbar
.navbar .navbar-nav > .open > a {
	color: $navbar-default-link-color;
}

.navbar .navbar-nav > .open > a:focus,
.navbar .navbar-nav > .open > a:hover,
.navbar .navbar-nav > li:hover > a {
	color: $navbar-default-brand-hover-color !important;
}

// inverted (dark) navbar
.navbar-inverse .navbar-nav > .open > a {
	color: $navbar-inverse-link-color;
}

.navbar-inverse .navbar-nav > .open > a:focus,
.navbar-inverse .navbar-nav > .open > a:hover,
.navbar-inverse .navbar-nav > li:hover > a {
	color: $navbar-inverse-brand-hover-color;
}
