$carousel-caption-background: rgba(0, 0, 0, .6);

.carousel-caption {
	text-align: left;

	p {
		padding: 8px 12px;
		background: $carousel-caption-background;
	}
}

.carousel-stretch {
	// scss-lint:disable SelectorDepth
	.carousel-inner > .item > img,
	.carousel-inner > .item > a > img {
		max-width: none;
		width: 100%;
	}
}

.intro-section {

	.carousel {
		margin: auto;

		&.carousel-has-caption {
			min-height: 400px;
		}
	}

	.carousel-has-caption {
		.item,
		.carousel-inner {
			min-height: 400px;
		}

		@media (max-width: 1120px) {
			// scss-lint:disable SelectorDepth
			.item > img {
				position: absolute;
				left: 50%;
				transform: translate(-50%, 0);
				height: 400px;
				// important necessary to overwrite .carousel-stretch
				// scss-lint:disable ImportantRule
				max-width: none !important;
				width: auto !important;
			}
		}
	}
}

