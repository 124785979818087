.spacer-15,
.spacer-30,
.spacer-45,
.spacer-60,
.spacer-75,
.spacer-90 {
	padding: 0;
	margin: 0;
	width: 100%;
}

.spacer-15 {
	height: 15px;
}

.spacer-30 {
	height: 30px;
}

.spacer-45 {
	height: 45px;
}

.spacer-60 {
	height: 60px;
}

.spacer-75 {
	height: 75px;
}

.spacer-90 {
	height: 90px;
}
