@import 'Helper/helper';
@import 'svg';
@import 'Theme/theme';
$fa-font-path: '../Fonts/FontAwesome';
@import 'FontAwesome/font-awesome';

@import '../../../node_modules/flag-icon-css/sass/variables';
$flag-icon-css-path: '../../../node_modules/flag-icon-css/flags';
@import '../../../node_modules/flag-icon-css/sass/flag-icon-base';
@include flag-icon(de);
@include flag-icon(gb);

@import url('https://fonts.googleapis.com/css?family=Lato:300,400');

// UI
@import 'Components/Ui/magnific-popup';
@import 'Components/Ui/spacer';
@import 'Components/Ui/shadowbox';
@import 'Components/Ui/accordion';
@import 'Components/Ui/content-element-styles';
@import 'Components/Ui/smart-labels';
@import 'Components/Ui/highlight-box';
@import 'Components/Ui/header-meta-bar';
@import 'Components/Ui/navbar';
@import 'Components/Ui/header';

// Plugins
@import 'Components/Plugins/dfTabs';
@import 'Components/Plugins/sg-news';
@import 'Components/Plugins/drop-up-component';
@import 'Components/Plugins/slider';
@import 'Components/Plugins/mobile-menu';
@import 'Components/Plugins/sg-comments';

// Partials
@import 'Components/Partials/cookie-alert';
@import 'Components/Partials/breadcrumb';

html {
	overflow-y: scroll;
}

html,
body {
	height: 100%;
	background-color: $white-base;
	-webkit-font-smoothing: antialiased;
}

.default-page-wrap {
	margin: auto;
	padding-top: 58px;
	background-color: $white-base;
	width: 100%;
	max-width: 1970px;
	box-shadow: 0 0 40px rgba(0, 0, 0, .2);
	position: relative;

	@media (min-width: $grid-float-breakpoint) {
		padding-top: 110px;
	}
}

::selection {
	color: $white-base;
	background: $gray1;
}

p {
	margin-bottom: $margin-base-vertical;
}

video,
audio {
	max-width: 100%;
}

// scss-lint:disable SingleLinePerSelector
.h1, .h2, .h3, .h4, .h5, .h6,
h1, h2, h3, h4, h5, h6 {
	margin-top: 0;
	padding-top: $margin-base-vertical;
	margin-bottom: 14px;
}

h1, .h1,
h2, .h2 {
	font-family: $font-family-first-headline;
}
.h3, .h4, .h5, .h6,
h3, h4, h5, h6 {
	font-family: $font-family-headlines;
}

.page-header {
	margin-top: 0;
	padding-top: $margin-base-vertical;
}

.btn {
	margin-bottom: $margin-base-vertical;
	vertical-align: top;

	&:last-child {
		margin-bottom: 0;
	}
}

.content,
.header {
	background-color: $white-base;
}

.content {
	padding: $margin-base-vertical * 2 0;
}

.default-content-element {
	margin-bottom: $margin-base-vertical;

	&:last-child {
		margin-bottom: 0;
	}
}

main {
	min-height: 500px;
	background-color: $white-base;
	margin-top:120px;
	padding-top:0px;
	xborder-top:1px solid green;
}

.main-content {
	// nasty trick to prevent margin collapse with footer
	padding-bottom: 1px;
	margin-bottom: -1px;
	
	margin-top:150px;

	> .default-content-element {
		@include container-fixed;

		&.row {
			padding-left: 0;
			padding-right: 0;
		}

		@media (min-width: $screen-sm-min) {
			width: $container-sm;
		}
		@media (min-width: $screen-md-min) {
			width: $container-md;
		}
		@media (min-width: $screen-lg-min) {
			width: $container-lg;
		}

		&:last-child {
			margin-bottom: $margin-base-vertical * 4;
		}
	}
}

.logo {
	max-width: 147px;

	top: -4px;
	position: relative;
	z-index:50;
}

ul {
	padding: 0;
	list-style-position: inside;
}

.footer {
	padding: $margin-base-vertical * 2 0;

	&.dark-bg {
		margin-top: 0;
		margin-bottom: 0;
	}
}

.well *:last-child {
	margin-bottom: 0;
}

.progressbar {
	margin-bottom: $margin-base-vertical;
}

.dark-bg {
	// this is a known and wanted violation of the CGL..
	// we need the top-margin here as there is no other way to add a distance between the section and the
	// content-element before it
	margin-top: $margin-base-vertical * 3;
	margin-bottom: $margin-base-vertical * 3;
	background: $gray1;
	color: $white-base;

	// elements inside the intro-section are an exception
	.intro-section & {
		margin: 0;
	}
}

.intro-section {
	margin-bottom: $margin-base-vertical * 2;
}

// form related styles
.parsley-errors-list {
	> li {
		padding: 5px 10px;
		color: $white-base;
		background-color: #AB0D10;
		list-style: none;
	}
}

.parsley-error {
	border-color: #AB0D10;
	border-radius: 0;
}

.btn-default {
	text-shadow: none;
}
