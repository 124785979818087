@import '../../../node_modules/magnific-popup/src/css/main';

.mfp-content {
	// scss-lint:disable QualifyingElement
	img.mfp-img {
		padding: 0;
	}

	.mfp-bottom-bar {
		margin-top: -20px;
	}

	.mfp-close {
		margin-top: -40px;
	}

	figure figcaption {
		position: relative;
		top: 25px;
	}

	> .ajax-wrapper {
		max-width: 980px;
		margin: 40px auto;
	}
}
