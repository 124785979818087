.border-top-bottom {

	> .default-content-element:first-child > *:first-child {
		border-top: 1px solid $brand-success;
		padding-top: 25px;
	}

	> .default-content-element:last-child > *:last-child {
		padding-bottom: 25px;
		border-bottom: 1px solid $brand-success;
	}
}

@media (min-width: $screen-tablet) {
	.row.equal-height-columns {
		display: flex;
		flex-wrap: wrap;

		&::before {
			width: 0;
		}

		[class^="col-"] {
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
		}

		&.stretch-first-child [class^="col-"] {
			.default-content-element:first-child {
				display: flex;
				flex-direction: column;
				flex-wrap: wrap;
			}

			// scss-lint:disable SelectorDepth
			.default-content-element:first-child,
			.default-content-element:first-child > *:first-child {
				flex-grow: 1;
			}
		}

		&.stretch-last-child [class^="col-"] {

			.default-content-element:last-child {
				display: flex;
				flex-direction: column;
				flex-wrap: wrap;
			}

			// scss-lint:disable SelectorDepth
			.default-content-element:last-child,
			.default-content-element:last-child > *:last-child {
				flex-grow: 1;
			}
		}

		&.align-childs-top [class^="col-"] {
			justify-content: flex-start;
		}

		&.align-childs-bottom [class^="col-"] {
			justify-content: flex-end;
		}

		&.align-childs-center [class^="col-"] {
			justify-content: center;
		}

		&.align-childs-equally [class^="col-"] {
			justify-content: space-between;
		}
	}
}
