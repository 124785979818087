// scss-lint:disable SelectorFormat

$nav-tabs-border: #DDD;
$nav-tabs-color: #555;

.tx-dftabs-tabMenu {
	margin-bottom: 15px;
}

.tx-dftabs-plugin1-hasJS .tx-dftabs-tabTitle,
.tx-dftabs-plugin1-hasJS .tx-dftabs-tabContent {
	display: none;
}

.tx-dftabs-plugin1-hasJS .tx-dftabs-tabMenu,
.tx-dftabs-plugin1-hasJS .tx-dftabs-tabContentSelected {
	display: block;
}

.tx-dftabs-plugin1-hasJS .tx-dftabs-tabContentSelected {
	overflow: hidden;
}

// scss-lint:disable QualifyingElement
.nav-tabs > li.tx-dftabs-tabMenuEntrySelected > a,
.nav-tabs > li.tx-dftabs-tabMenuEntrySelected > a:focus,
.nav-tabs > li.tx-dftabs-tabMenuEntrySelected > a:hover {
	color: $nav-tabs-color;
	cursor: default;
	background-color: $white-base;
	border: 1px solid $nav-tabs-border;
	border-bottom-color: transparent;
}
