$navbar-sticky-box-shadow-color: rgba(0, 0, 0, .5);
$navbar-separator-color: rgba(255, 255, 255, .3);

.navbar-default {
	display: none;

	// scss-lint:disable SelectorDepth
	.dropdown > a > .flag-icon {
		display: none;
	}

	@media (min-width: $grid-float-breakpoint) {
		display: block;
		margin-left:240px;
	}

	.hide-in-desktop-menu {
		display: none;
	}

	.glyphicon,
	.flag-icon {
		margin-right: 4px;
	}
}

.default-navbar-sticky {
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	width: 100%;
	box-shadow: 0 0 5px 0 $navbar-sticky-box-shadow-color;

	.navbar-nav {
		position: relative;
	}
}

.navbar-nav.navbar-left li {
	border-bottom:1px solid $gray5;
	padding-left:0px;
	padding-right:0px;
}
.navbar-nav.navbar-left li li {
	padding-left:0px;
	padding-right:0px;
	border:0px;
}
.navbar-nav.navbar-left > li:first-child a {
	xpadding-left: 0;
}

.navbar-separator-right {
	&::after {
		content: '';
		display: inline-block;
		position: absolute;
		top: 30%;
		right: 0;
		bottom: 25%;
		width: 1px;
		background-color: $navbar-separator-color;
	}
}

.navbar-separator-left {
	position: relative;

	&::before {
		content: '';
		display: inline-block;
		position: absolute;
		top: 30%;
		left: 0;
		bottom: 25%;
		width: 1px;
		background-color: $navbar-separator-color;
	}
}

.dropdown-menu {
	padding-top: 0;
	padding-bottom: 0;
}
