$sg-comments-comment-border-color: rgba(240, 240, 240, 0);

#comments {
	h2 {
		margin-bottom: 35px;
	}

	.comment {
		@include pie-clearfix;
		margin-bottom: 25px;
	}

	.comment-inner {
		width: 85%;
		padding: 25px;
		position: relative;
		background: $gray-lighter;
		float: left;
	}

	.replies {
		margin: 20px 0 0 5%;
	}

	.comment-inner::after {
		right: 100%;
		top: 30px;
		content: ' ';
		height: 0;
		width: 0;
		position: absolute;
		pointer-events: none;
		border: 30px solid $sg-comments-comment-border-color;
		border-right-color: $gray-lighter;
		margin-top: 0;
	}

	.comment-image {
		float: left;
		width: 15%;
	}

	@media (max-width: $screen-md-min) {
		.comment-image {
			display: none;
		}

		.comment-inner::after {
			display: none;
		}
	}

	.date {
		padding: 0 0 10px;
		display: block;
		color: lighten($gray-base, 40%);
	}
}

#write-comment {
	background-color: $gray-lighter;

	#comments-form {
		margin: 10px 15% 20px;
		max-width: 590px;

		#write-new-comment {
			display: none;
			float: right;
		}
	}

	@media (max-width: $screen-md-min) {
		#comments-form {
			margin-left: 20px;
			margin-right: 20px;
		}
	}
}
