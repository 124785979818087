@function str-replace($string, $search, $replace: '') {
	$index: str-index($string, $search);

	@if $index {
		@return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
	}

	@return $string;
}

$loading-spinner: 'data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2232%22%20height%3D%2232%22%20viewBox%3D%220%200%2032%2032%22%3E%3Cpath%20fill%3D%22%23FFF%22%20d%3D%22M16%2032c-4.274%200-8.292-1.664-11.314-4.686S0%2020.274%200%2016c0-3.026.85-5.973%202.456-8.522a16.052%2016.052%200%200%201%206.386-5.79l1.344%202.68A13.054%2013.054%200%200%200%204.994%209.08%2012.934%2012.934%200%200%200%203%2015.997c0%207.17%205.832%2013%2013%2013s13-5.83%2013-13c0-2.458-.69-4.852-1.994-6.92a13.05%2013.05%200%200%200-5.192-4.71l1.344-2.68a16.045%2016.045%200%200%201%206.386%205.79A15.97%2015.97%200%200%201%2032%2016c0%204.274-1.664%208.292-4.686%2011.314S20.274%2032%2016%2032z%22%2F%3E%3C%2Fsvg%3E' 32px 32px;

@mixin inline-svg($name, $color: null) {
	@if ($color) {
		$color: str-replace($color + '', '#', '');
		background: transparent url(str-replace(nth($name, 1), '%23FFF', '%23' + $color)) no-repeat 50% 50%;
		color: $color;
	} @else {
		background: transparent url(nth($name, 1)) no-repeat 50% 50%;
	}
	background-size: 100%;
	width: nth($name, 2);
	height: nth($name, 3);
}

@function inline-svg-width($name) {
	@return nth($name, 2);
}

@function inline-svg-height($name) {
	@return nth($name, 3);
}
